import styled, { WebTarget } from "styled-components";
import { rgba } from "polished";
import { scale, colors, breakpoint } from "../../styles";
import {
  AccordionHeaderProps,
  AccordionPanelStyledProps,
  AccordionToggleProps,
  PanelInnerStyledProps,
} from "./types";
import { space } from "../../styles/styled-system";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export const accordionBorderColorLight = rgba(colors["white"], 0.12);
export const accordionBorderColorDark = rgba(colors["black"], 0.12);

export const HeaderStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<AccordionHeaderProps>`
  display: flex;
  padding: ${scale.px(2)} 0;
  height: ${(props) => (props.noBorderExpanded ? "65px" : scale.px(8))};
  background-color: transparent;
  color: ${({ theme }) =>
    theme.dark ? colors["white"] : colors["black-light"]};
  border-bottom-width: ${(props) =>
    props.noBorderExpanded && props.expanded ? 0 : "1px"};
  border-bottom-style: ${({ noBorder }) => noBorder ? "" : "solid" };
  border-color: ${({ theme }) =>
    theme.dark ? accordionBorderColorLight : accordionBorderColorDark};
  overflow: hidden;

  a {
    color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black-light"]};
  }

  ${breakpoint("m")`
		padding-left: ${scale.px(2.5)};
	`}
`;

export const PanelStyled = styled.div
  .attrs<AccordionPanelStyledProps>((props) => ({
    id: `panel-${props.id}`,
    role: "region",
    "aria-labelledby": `header-${props.id}`,
    "aria-hidden": props.expanded ? `false` : `true`,
  }))
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !["expanded", "lessPadding"].includes(prop),
  })<AccordionPanelStyledProps>`
  height: ${(props) => (props.expanded ? "auto" : "0")};
  visibility: ${(props) => (props.expanded ? "visible" : "hidden")};
  overflow: hidden;
  color: ${({ theme }) =>
    theme.dark ? colors["white"] : colors["black-light"]};
`;

export const PanelInnerStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<PanelInnerStyledProps>`
  ${space}
`;

export const ToggleStyled = styled.button
  .attrs<AccordionToggleProps>((props) => ({
    id: `header-${props.id}`,
    "aria-expanded": props.expanded ? `true` : `false`,
    "aria-controls": `panel-${props.id}`,
    "aria-label": props.toggleLabel || undefined,
  }))
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !noForwardProps.includes(prop),
  })<AccordionToggleProps>`
	position: relative;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	width: ${scale.px(6)};
	border-left: ${({ noBorder }) => noBorder ? "" : "1px solid" };
	border-color: ${({ theme }) =>
    theme.dark ? accordionBorderColorLight : accordionBorderColorDark};

	&:before,
	&:after {
		content: "";
		display: block;
		width: ${scale.px(2.5)};
		height: 2px;
		background-color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black-light"]};
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}

	&:after {
		transition: transform 0.2s cubic-bezier(0.7, 0, 0.84, 0);
		transform: ${(props) =>
      props.expanded
        ? `translateY(-50%) translateX(-50%) rotate(0)`
        : ` translateY(-50%) translateX(-50%) rotate(-90deg)`};
	}
`;
