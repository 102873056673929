"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { scale, breakpoint, colors, isDefaultTheme } from "../../styles";
import { TRANSITION_TIMING, HEADER_HEIGHT } from "../../constants";

export interface HeaderProps {
  logo: any;
}

export interface HeaderStyledProps {
  fixed: boolean;
  visible?: boolean;
  ref?: React.Ref<HTMLHeadElement>;
}

const HeaderStyled = styled.header.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["fixed"].includes(prop),
})<HeaderStyledProps>`
  position: ${(props) => (props.fixed ? "fixed" : "relative")};
  display: block;
  width: 100%;
  height: ${scale.px(HEADER_HEIGHT)};
  padding: 0 ${scale.px(2)};
  border-bottom: 1px solid;
  border-color: ${({ theme }) =>
    theme.dark ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  background-color: ${({ theme }) =>
    isDefaultTheme(theme) ? colors["off-white"] : theme.primaryColor};
  transition: background-color 0.3s ${TRANSITION_TIMING};
  color: ${({ theme }) => theme.primaryColor};
  opacity: ${(props) => (props.visible ? 0 : 1)};
  z-index: 100;

  ${breakpoint("m")`
		padding: 0 ${scale.px(5)};
	`}

  ${breakpoint("xl")`
		padding-right: 0;
		height: auto; /* ${scale.px(9)}; */
		border-bottom: ${({ theme }) => (theme.dark ? "none" : "1px solid")};
	`}
`;

const Header = React.forwardRef<HTMLHeadElement, React.PropsWithChildren<HeaderStyledProps>>((
  props, 
  ref
) => {
  const { children, fixed } = props;

  return (
    <HeaderStyled fixed={fixed} role="banner" ref={ref}>
      {children}
    </HeaderStyled>
  );
});

export { Header };
