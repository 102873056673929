"use client";
import * as React from "react";
import styled, { useTheme } from "styled-components";
import { Icon } from "../";
import { scale } from "../../styles";

export interface ChevronProps {
  open: boolean;
}

const ChevronWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${scale.px(3)};
  height: ${scale.px(2.25)};
  margin-bottom: ${scale.px(-0.5)};
  margin-left: ${scale.px(-0.5)};
  transition: transform 0.3s ease;
  transform-origin: 40% 50%;
`;

const ChevronStyled = styled.div<ChevronProps>`
  display: inline-block;
  margin: 0;
  transition: opacity 0.15s linear, filter 0.15s linear;

  ${ChevronWrapper} {
    ${(props) =>
      props.open &&
      `
        transform: rotate(-180deg);
    `}
  }
`;

const ChevronToggle: React.FC<ChevronProps> = ({ open = false }) => {
  const theme = useTheme(); // Assuming you're using a theme

  return (
    <ChevronStyled open={open}>
      <ChevronWrapper>
        <Icon
          icon="chevron-down"
          size={2.5} // Use the size you provided
          color={theme.dark ? "white" : "black"} // Use the theme to determine color
        />
      </ChevronWrapper>
    </ChevronStyled>
  );
};

export { ChevronToggle };