"use client";
import * as React from "react";
import Link from "next/link";
import styled, { WebTarget } from "styled-components";
import { scale, colors, breakpoint, isDefaultTheme } from "../../styles";
import { SpaceProps } from "../../styles/styled-system";

export interface BreadcrumbsProps extends SpaceProps {
  links?: BreadcrumbsLink[];
}

export interface BreadcrumbsLinkStyledProps {
  current?: boolean;
}

export interface BreadcrumbsLink {
  id: string;
  title: string;
  url: string;
}

const BreadcrumbsStyled = styled.nav`
  position: absolute;
  top: ${scale.px(1)};
  z-index: 1;

  & > div {
    background: ${({ theme }) =>
      isDefaultTheme(theme) ? colors["off-white"] : theme.primaryColor};
  }
`;

const BreadcrumbsInner = styled.div`
  display: flex;
  padding-left: ${({ theme }) => (isDefaultTheme(theme) ? 0 : scale.px(1))};
  padding-right: ${({ theme }) => (isDefaultTheme(theme) ? 0 : scale.px(1))};
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: ${scale.px(2)};

  ${breakpoint("m")`
		margin-left: ${scale.px(8)};
	`}
`;

const BreadcrumbsExpandBtnStyled = styled.span`
  cursor: pointer;
  font-size: ${scale.px(1.75)};
  color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
`;

const BreadcrumbsItemStyled = styled.li`
  position: relative;
  display: inline;

  &.hidden-desktop {
    ${breakpoint("m")`
			display: none;
		`}
  }

  &.hidden-mobile {
    display: none;

    ${breakpoint("m")`
			display: inline;
		`}
  }

  &:not(:last-child):after {
    content: "\\002F";
    padding: 0 ${scale.px(1)};
    font-size: ${scale.px(1.75)};
    color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
  }
`;

const BreadcrumbsLinkStyled = styled(Link)
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !["current"].includes(prop),
  })
  .attrs<BreadcrumbsLinkStyledProps>((props) =>
    props.current
      ? {
          "aria-current": "page",
        }
      : {}
  )<BreadcrumbsLinkStyledProps>`
  font-size: ${scale.px(1.75)};
  line-height: ${scale.px(2.25)};
  text-decoration: ${(props) => (props.current ? "none" : "underline")};
  color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { links, ...rest } = props;
  const [isExpanded, setExpandedState] = React.useState(false);
  const toggleExpandedState = () => setExpandedState(!isExpanded);

  return links && links.length > 0 ? (
    <BreadcrumbsStyled {...rest}>
      <BreadcrumbsInner>
        <ul>
          {links.map((link, index) => {
            const intermediateItem =
              links.length > 2 && index !== 0 && index !== links.length - 1;
            return (
              <React.Fragment key={link.url}>
                {index === 1 && links.length > 2 && !isExpanded && (
                  <BreadcrumbsItemStyled
                    className="hidden-desktop"
                    onClick={toggleExpandedState}
                  >
                    <BreadcrumbsExpandBtnStyled>...</BreadcrumbsExpandBtnStyled>
                  </BreadcrumbsItemStyled>
                )}
                <BreadcrumbsItemStyled
                  className={`${
                    intermediateItem && !isExpanded ? "hidden-mobile" : ""
                  }`}
                >
                  <BreadcrumbsLinkStyled
                    href={link.url}
                    id={link.id}
                    current={index === links.length - 1}
                    prefetch={false}
                    className="breadcrumb-link"
                  >
                    {link.title}
                  </BreadcrumbsLinkStyled>
                </BreadcrumbsItemStyled>
              </React.Fragment>
            );
          })}
        </ul>
      </BreadcrumbsInner>
    </BreadcrumbsStyled>
  ) : null;
};

export { Breadcrumbs };
