"use client";
import * as React from "react";
import styled from "styled-components";
import { colors, scale } from "../../styles";

export interface SkipLinkProps {
  label: string;
  handleFocus?: () => void;
  handleBlur?: () => void;
}

const SkipLinkStyled = styled.a`
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 100%;
  height: 1px;
  overflow: hidden;
  text-align: center;
  display: block;
  padding: ${scale.px(1)} ${scale.px(3)};
  background-color: ${colors["gold-light"]};
  color: ${colors["black"]};

  &:active,
  &:focus,
  &:hover {
    position: relative;
    left: 0;
    top: 0;
    height: auto;
    overflow: visible;

    span {
      outline-width: 2px;
      outline-style: solid;
      outline-color: Highlight;
    }
  }
`;

const elementIsVisible = (elem: Element | null): boolean => {
  while (elem) {
    const style = window.getComputedStyle(elem as HTMLElement);
    if (style.display === "none" || style.visibility === "hidden") {
      return false;
    }
    elem = elem.parentElement;
  }
  return true;
};

const SkipLink = React.forwardRef<HTMLAnchorElement, SkipLinkProps>((props, ref) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault(); // Prevent default scroll to the anchor
    
    const mainContent = document.getElementById("main");
  
    if (mainContent) {
      // Get all focusable elements
      const allFocusableElements = Array.from(
        mainContent.querySelectorAll(
          'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
        )
      );
  
      // Filter out certain elements
      const focusableElements = allFocusableElements.filter(
        (el) => {
          const conditions = [
            !el.classList.contains("breadcrumb-link"),
            !el.ariaLabel?.match("play"),
            elementIsVisible(el)
          ];

          return conditions.every((condition) => condition);
      });
  
      if (focusableElements.length > 0) {
        // Focus the first valid element
        (focusableElements[0] as HTMLElement).focus();
      } 
    }
  };

  return (
    <SkipLinkStyled href="#main" onClick={handleClick} ref={ref} onFocus={props.handleFocus} onBlur={props.handleBlur}>
      <span>{props.label}</span>
    </SkipLinkStyled>
  );
});

export { SkipLink };