"use client";
import * as React from "react";
import { LinkProps } from "next/link";
import {
  MenuStyled,
  ListStyled,
  ListItemStyled,
  LinkStyled,
  MenuStyledProps,
  ListStyledProps,
  ListItemStyledProps,
  MenuLinkStyledProps,
  DropdownLinkStyled,
} from "./styled";

export interface MenuProps {
  List: React.FC<ListStyledProps>;
  Item: React.FC<ListItemStyledProps>;
  Link: React.FC<LinkProps & MenuLinkStyledProps>;
  DropdownLink: React.FC<MenuLinkStyledProps>;
  open?: boolean;
}

export const MenuContext = React.createContext({
  open: false,
});

const Menu: React.FC<MenuStyledProps> & MenuProps = ({
  open = false,
  ...props
}) => (
  <MenuContext.Provider value={{ open: open }}>
    <MenuStyled {...props} />
  </MenuContext.Provider>
);

Menu.List = ListStyled;

const Item = (props: any) => {
  const { open } = React.useContext(MenuContext);
  return <ListItemStyled open={open} {...props} />;
};

Menu.Item = Item;

Menu.Link = LinkStyled;

Menu.DropdownLink = DropdownLinkStyled;

export { Menu };
