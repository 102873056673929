"use client";
import styled from "styled-components";
import {
  HeaderStyled as AccordionHeader,
  ToggleStyled as AccordionToggle,
  PanelStyled as AccordionPanel,
  PanelInnerStyled as AccordionPanelInner,
} from "../Accordion/styled";
import { ListItemStyled as SubmenuListItem } from "../Submenu";
import { scale, breakpoint, colors } from "../../styles";
import { LinkStyled } from "../Menu/styled";

export const SubnavStyled = styled.div`
  position: absolute;
  top: ${scale.px(-12)};
  left: ${scale.px(-2)};
  width: calc(100% + ${scale.px(4)});
  z-index: 10;

  ${breakpoint("m")`
		left: ${scale.px(-5)};
		width: calc(100% + ${scale.px(10)});
	`}

  ${breakpoint("l")`
		position: absolute;
		top: ${scale.px(2)};
		left: auto;
		right: 0;
		display: flex;
		flex-direction: column;
		width: ${scale.px(33)};
		margin-left: auto;
		margin-bottom: -200px;
	`}

	${breakpoint("xl")`
		width: ${scale.px(38)};
	`}

	${breakpoint("xxxl")`
		width: ${scale.px(42)};
	`}

	${LinkStyled},
	${SubmenuListItem} {
    &:before {
      background-color: ${({ theme }) =>
        theme.dark ? colors["greybrown"] : colors["black"]};
    }
  }

  ${AccordionHeader},
  ${AccordionToggle},
	${AccordionPanel} {
    background-color: ${({ theme }) =>
      theme.dark ? colors["black"] : colors["greybrown"]};

    ${breakpoint("l")`
			background-color: transparent;
		`}
  }

  ${AccordionToggle} {
    border-color: ${({ theme }) =>
      theme.dark ? `rgba(255, 255, 255, 0.2)` : `rgba(0, 0, 0, 0.2)`};
  }

  ${AccordionHeader} {
    padding-right: ${scale.px(1)};
    padding-left: ${scale.px(2.5)};
    border-color: transparent;

    ${breakpoint("m")`
			padding-left: ${scale.px(5)};
			padding-right: ${scale.px(4)};
		`}

    ${breakpoint("l")`
			padding-left: ${scale.px(2)};
			padding-right: 0;
			border-color: ${({ theme }) =>
        theme.dark ? `rgba(255, 255, 255, 0.2)` : `rgba(0, 0, 0, 0.2)`};
		`}
  }

  ${AccordionPanelInner} {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    ${breakpoint("l")`
			padding: ${scale.px(1)} ${scale.px(3)} ${scale.px(1)} ${scale.px(3)};
		`}

    ${AccordionHeader} {
      padding-left: ${scale.px(5)};
    }

    ${AccordionPanelInner} {
      padding-left: ${scale.px(7)};
      padding-right: ${scale.px(6)};

      ${breakpoint("l")`
				padding: ${scale.px(1)} ${scale.px(3)} ${scale.px(1)} ${scale.px(3)};
			`}
    }
  }
`;

const Subnav: React.FC<React.PropsWithChildren> = (props) => <SubnavStyled {...props} />;

export { Subnav };
