"use client";
import * as React from "react";
import styled from "styled-components";
import { scale, colors, breakpoint } from "../../styles";

export interface FoldoutProps {
  open: boolean;
  children: React.ReactNode;
}

export type FoldoutOpenState = true | false;

export interface FoldoutStyledProps {
  open: FoldoutOpenState;
}

const FoldoutStyled = styled.div<FoldoutStyledProps>`
  position: fixed;
  top: ${scale.px(8)};
  width: 100%;
  background-color: ${colors["white"]};
  padding-top: ${scale.px(0)};
  overflow: ${(props) => (props.open ? "auto" : "hidden")};
  -webkit-overflow-scrolling: touch;
  z-index: 100;

  height: ${(props) => (props.open ? "100%" : 0)};
  transition-delay: ${(props) => (props.open ? 0 : `0.5s`)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};

  ${breakpoint("xl")`
		display: none;
	`}
`;

const Foldout: React.FC<FoldoutProps> = ({ open = false, ...props }) => (
  <FoldoutStyled open={open} {...props} />
);

export { Foldout };
